:root {
  --font-family: 'Poppins', 'sans-serif';
  --h2-font-size: 2.5rem;
  --main-color-dark: #171429;
  --main-color: #2A254D;
  --main-color-light: #A59FD1;
  --light-color: #F2F1F8;
  --yellow-color: #FCD318;
  --darker-yellow-color: #C9A503;
  --white-color: #FFFFFF;
  --black-color: #000000;
}

* {
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--light-color);
}

@font-face {
  font-family: 'Poppins';
  src: 
    url('./fonts/Poppins/Poppins-ExtraBold.ttf') format('truetype');
  font-weight: 800;
}

@font-face {
  font-family: 'Poppins';
  src: url('./fonts/Poppins/Poppins-Bold.ttf') format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: 'Poppins';
  src: url('./fonts/Poppins/Poppins-SemiBold.ttf') format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: 'Poppins';
  src: url('./fonts/Poppins/Poppins-Regular.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Poppins';
  src: url('./fonts/Poppins/Poppins-Light.ttf') format('truetype');
  font-weight: 200;
}





code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
